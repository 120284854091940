.calendar {
  &.e-schedule {
    border-left-width: 0px;
  }
  .e-timeline-view {
    .e-resource-left-td {
      width: 250px; //sets the width of the resource column
      border-right-width: 1px !important;
    }
    .e-resource-cells {
      padding-left: 0 !important;
      border-width: 0 1px 0 0 !important;
    }
    .e-alternate-cells {
      border-left-width: 0;
    }

    .e-appointment {
      height: unset !important; //removes height in timeline view so that the appointment can be taller

      .e-appointment-details {
        padding: 0 1px !important;//remove white border to left and right on appointment
      }
    }

    .e-schedule-table {
      .e-header-row {
        &:first-child {
          display: none; //hides the date row in timeline row
        }
        .e-time-slots {
          height: 75px;
        }
      }
      .e-work-cells {
        height: 75px;
        &.alternate-cells {
          border-width: 0;
        }
      }
    }
  }

  .e-vertical-view {
    .e-header-cells {
      height: 69px;
    }
    .e-appointment {
      display: flex;
      .e-appointment-details {
        max-width: 100%; // don't get bigger than the parent element or else the right border is hidden
        display: flex;
      }
    }
    .e-left-indent-wrap {
      table tbody td.e-header-cells {
        height: 70px;
      }
    }
    .e-day-wrapper {
      .e-appointment {
        min-height: 57px;
      }
    }
    .e-all-day-appointment-wrapper {
      .e-appointment {
        height: 28px;
        .event-date {
          border-bottom-style: solid;
          border-bottom-width: 3px;
        }
      }
    }
  }

  .e-content-wrap {
    &::-webkit-scrollbar {
      //display: none !important; //commented out because we need a scroll bar on the timeline view
    }
  }

  .e-date-header-container {
    //padding-right:0px !important; //remove padding so that was added when there is no scroll bar
  }

  .e-month-view {
    .e-header-cells {
      height: 43px;

      span {
        //for some reason the month display of the calendar doesn't call the dateHeaderTemplate. Assume it will get fixed eventually?
        display: block;
        font-size: 12;
        color: #aaaaaa; // theme.palette.grey.A200,
        text-align: center;
        text-transform: uppercase;
      }
    }

    .e-current-date {
      border: 1px solid #060e4f !important;
      border-bottom: 5px solid #060e4f !important;
      //background-color: #e0e0e0  !important;

      .e-date-header {
        background-color: transparent !important;
        color: rgba(0, 0, 0, 0.87)  !important;
      }
    }

    .e-selected-cell {
      border: 1px solid #0090D2 !important;
      border-bottom: 5px solid #0090D2 !important;
      background-color: transparent !important;

    }
    .e-other-month {
      background-color: #f6f8fb  !important;
    }

    .e-appointment {
      height: 28px;
    }
  }

  .e-more-popup-wrapper {
    .e-appointment {
      height: 28px;
      padding: 0;
    }
  }

  .e-appointment {
    background-color: transparent !important;
    border: 0 !important;
  }

  .e-input-group::before,
  .e-input-group::after,
  .e-input::selection {
    background: #4a90e2 !important;
  }

  .e-popup-content {
    .e-input {
      font-size: 17px !important;
    }
    .e-date-time {
      padding-top: 8px;
    }
  }

  .e-popup-footer {
    button {
      margin: 7px;
      text-transform: initial !important;
      border: 1px solid #2189f8 !important;
      font-size: 13px;
      padding: 3px 16px;
      min-height: 32px;
      min-width: 32px;
      background-color: transparent !important;
      color: black !important;
      &:hover {
        background-color: rgba(0, 0, 0, 0.08) !important;
      }

      &.e-event-details {
        border-radius: 4px 0px 0px 4px;
        border-right: 1px solid rgba(0, 0, 0, 0.08) !important;
        margin-right: 0;
      }
      &.e-event-create {
        border-radius: 0px 4px 4px 0px;
        border-left: none !important;
        margin-left: 0;
      }
    }
  }
}

.hidden {
  display: none;
}

.minicalender {
  .e-calendar {
    .e-content {
      td.e.selected {
        span.e-day {
        background-color: #0090D2 !important;
        }
      }
    }
  }
}

.md-tooltip .mbsc-popup-content {
  padding: 0;
}

.md-tooltip {
  font-size: 15px;
  font-weight: 600;
}

.md-tooltip-header {
  padding: 12px 16px;
  color: #eee;
}

.md-tooltip-info {
  padding: 16px 16px 60px 16px;
  position: relative;
  line-height: 32px;
}

.md-tooltip-time,
.md-tooltip-status-button {
  float: right;
}

.md-tooltip-title {
  margin-bottom: 15px;
}

.md-tooltip-text {
  font-weight: 300;
}

.md-tooltip-info .mbsc-button {
  font-size: 14px;
  margin: 0;
}

.md-tooltip-info .mbsc-button.mbsc-material {
  font-size: 12px;
}

.md-tooltip-view-button {
  position: absolute;
  bottom: 16px;
  left: 16px;
}

.md-tooltip-delete-button {
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.mbsc-calendar-header {
  .mbsc-calendar-controls {
    margin-bottom: -10px;
  }
}

.mbsc-calendar-wrapper.mbsc-flex-col.mbsc-flex-1-1.mbsc-ios.mbsc-calendar-wrapper-fixed > div.mbsc-calendar-body.mbsc-flex-col.mbsc-flex-1-1.mbsc-ios {
  padding-top: 23px;
  background: #f7f7f7;
}

