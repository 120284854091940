
td.e-selected span.e-day {
  background-color: #0090D2 !important;

}
td.e-today span.e-day{
  color: #060e4f !important;
  border: 1px solid #060e4f !important;
}

