html,
body,
.root {
  height: 100%;
}

html {
  background-color: transparent !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
}

.k-grid-header {
  background-color: unset !important;
}

table {
  border-collapse: collapse;
  width: 100%;

  th {
    font-weight: 400;
    text-align: left;
  }
}

button {
  cursor: pointer;
}
