html,
body,
.root {
  height: 100%; }

html {
  background-color: transparent !important; }

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", "Helvetica Neue", sans-serif; }

.k-grid-header {
  background-color: unset !important; }

table {
  border-collapse: collapse;
  width: 100%; }
  table th {
    font-weight: 400;
    text-align: left; }

button {
  cursor: pointer; }

.calendar.e-schedule {
  border-left-width: 0px; }

.calendar .e-timeline-view .e-resource-left-td {
  width: 250px;
  border-right-width: 1px !important; }

.calendar .e-timeline-view .e-resource-cells {
  padding-left: 0 !important;
  border-width: 0 1px 0 0 !important; }

.calendar .e-timeline-view .e-alternate-cells {
  border-left-width: 0; }

.calendar .e-timeline-view .e-appointment {
  height: unset !important; }
  .calendar .e-timeline-view .e-appointment .e-appointment-details {
    padding: 0 1px !important; }

.calendar .e-timeline-view .e-schedule-table .e-header-row:first-child {
  display: none; }

.calendar .e-timeline-view .e-schedule-table .e-header-row .e-time-slots {
  height: 75px; }

.calendar .e-timeline-view .e-schedule-table .e-work-cells {
  height: 75px; }
  .calendar .e-timeline-view .e-schedule-table .e-work-cells.alternate-cells {
    border-width: 0; }

.calendar .e-vertical-view .e-header-cells {
  height: 69px; }

.calendar .e-vertical-view .e-appointment {
  display: flex; }
  .calendar .e-vertical-view .e-appointment .e-appointment-details {
    max-width: 100%;
    display: flex; }

.calendar .e-vertical-view .e-left-indent-wrap table tbody td.e-header-cells {
  height: 70px; }

.calendar .e-vertical-view .e-day-wrapper .e-appointment {
  min-height: 57px; }

.calendar .e-vertical-view .e-all-day-appointment-wrapper .e-appointment {
  height: 28px; }
  .calendar .e-vertical-view .e-all-day-appointment-wrapper .e-appointment .event-date {
    border-bottom-style: solid;
    border-bottom-width: 3px; }

.calendar .e-month-view .e-header-cells {
  height: 43px; }
  .calendar .e-month-view .e-header-cells span {
    display: block;
    font-size: 12;
    color: #aaaaaa;
    text-align: center;
    text-transform: uppercase; }

.calendar .e-month-view .e-current-date {
  border: 1px solid #060e4f !important;
  border-bottom: 5px solid #060e4f !important; }
  .calendar .e-month-view .e-current-date .e-date-header {
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.87) !important; }

.calendar .e-month-view .e-selected-cell {
  border: 1px solid #0090D2 !important;
  border-bottom: 5px solid #0090D2 !important;
  background-color: transparent !important; }

.calendar .e-month-view .e-other-month {
  background-color: #f6f8fb  !important; }

.calendar .e-month-view .e-appointment {
  height: 28px; }

.calendar .e-more-popup-wrapper .e-appointment {
  height: 28px;
  padding: 0; }

.calendar .e-appointment {
  background-color: transparent !important;
  border: 0 !important; }

.calendar .e-input-group::before,
.calendar .e-input-group::after,
.calendar .e-input::selection {
  background: #4a90e2 !important; }

.calendar .e-popup-content .e-input {
  font-size: 17px !important; }

.calendar .e-popup-content .e-date-time {
  padding-top: 8px; }

.calendar .e-popup-footer button {
  margin: 7px;
  text-transform: none !important;
  text-transform: initial !important;
  border: 1px solid #2189f8 !important;
  font-size: 13px;
  padding: 3px 16px;
  min-height: 32px;
  min-width: 32px;
  background-color: transparent !important;
  color: black !important; }
  .calendar .e-popup-footer button:hover {
    background-color: rgba(0, 0, 0, 0.08) !important; }
  .calendar .e-popup-footer button.e-event-details {
    border-radius: 4px 0px 0px 4px;
    border-right: 1px solid rgba(0, 0, 0, 0.08) !important;
    margin-right: 0; }
  .calendar .e-popup-footer button.e-event-create {
    border-radius: 0px 4px 4px 0px;
    border-left: none !important;
    margin-left: 0; }

.hidden {
  display: none; }

.minicalender .e-calendar .e-content td.e.selected span.e-day {
  background-color: #0090D2 !important; }

.md-tooltip .mbsc-popup-content {
  padding: 0; }

.md-tooltip {
  font-size: 15px;
  font-weight: 600; }

.md-tooltip-header {
  padding: 12px 16px;
  color: #eee; }

.md-tooltip-info {
  padding: 16px 16px 60px 16px;
  position: relative;
  line-height: 32px; }

.md-tooltip-time,
.md-tooltip-status-button {
  float: right; }

.md-tooltip-title {
  margin-bottom: 15px; }

.md-tooltip-text {
  font-weight: 300; }

.md-tooltip-info .mbsc-button {
  font-size: 14px;
  margin: 0; }

.md-tooltip-info .mbsc-button.mbsc-material {
  font-size: 12px; }

.md-tooltip-view-button {
  position: absolute;
  bottom: 16px;
  left: 16px; }

.md-tooltip-delete-button {
  position: absolute;
  bottom: 16px;
  right: 16px; }

.mbsc-calendar-header .mbsc-calendar-controls {
  margin-bottom: -10px; }

.mbsc-calendar-wrapper.mbsc-flex-col.mbsc-flex-1-1.mbsc-ios.mbsc-calendar-wrapper-fixed > div.mbsc-calendar-body.mbsc-flex-col.mbsc-flex-1-1.mbsc-ios {
  padding-top: 23px;
  background: #f7f7f7; }

td.e-selected span.e-day {
  background-color: #0090D2 !important; }

td.e-today span.e-day {
  color: #060e4f !important;
  border: 1px solid #060e4f !important; }

.k-list .k-item.k-state-selected {
  color: #fff;
  background-color: #4A90E2 !important; }

.style1 {
  color: #4A90E2;
  text-decoration: underline;
}

.style2 {
  font-weight: bold;
}

.ReactCrop__image{
    max-height: 300px;
}
body {
  height: 100vh;
  background-image: radial-gradient(closest-side, #d4d7e2, #ffffff);
  text-align: left;
  color: #070E4F;
  font-size: 16px;
  line-height: 1.5;
}
.logo {
  margin: 40px auto;
  display: block;
}
h1 {
  font-size: 24px;
  line-height: 40px;
  margin: 0 auto 16px;
  padding: 0 20px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  letter-spacing: 0.5px;
  font-weight: 600;
  max-width: 600px;
}
p a {
  color: inherit;
}
p a:hover {
  color: #070E4F;
}
.button {
  color: #2ea3f2;
  background: #fff;
  border-radius: 3px;
  display: inline-block;
  padding: 12px 48px;
  text-decoration: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.button:hover {
  margin-top: -2px;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.4);
}
.browser {
  width: 400px;
  min-width: 200px;
  min-height: 264px;
  background: #fff;
  box-shadow: 0 40px 80px 0 rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  -webkit-animation: bobble 1.8s ease-in-out infinite;
          animation: bobble 1.8s ease-in-out infinite;
  position: relative;
  left: 50%;
  margin-left: -200px;
}
.browser .controls {
  width: 100%;
  height: 32px;
  background: #a2d7fe;
  border-radius: 3px 3px 0 0;
  box-sizing: border-box;
  padding: 10px 12px;
}
.browser .controls i {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  display: block;
  float: left;
  background: #d6dce0;
  margin-right: 8px;
}
.eye {
  position: absolute;
  left: 34%;
  top: 80px;
  width: 32px;
  height: 32px;
  opacity: 1;
}
.eye + .eye {
  right: 34%;
  left: auto;
}
.eye:before, .eye:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 40px;
  width: 3px;
  border-radius: 2px;
  background-color: #238AF9;
}
.eye:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.eye:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.mouth {
  position: absolute;
  width: 250px;
  top: 178px;
  left: 50%;
  margin-left: -125px;
  height: 40px;
}
.mouth .lips {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 40px;
  width: 3px;
  border-radius: 2px;
  background-color: #238AF9;
  -webkit-transform: rotate(-54deg);
          transform: rotate(-54deg);
}
.mouth .lips:nth-child(odd) {
  -webkit-transform: rotate(54deg);
          transform: rotate(54deg);
}
.mouth .lips:nth-child(n+2) {
  margin-left: 31px;
}
.mouth .lips:nth-child(n+3) {
  margin-left: 62px;
}
.mouth .lips:nth-child(n+4) {
  margin-left: 93px;
}
.mouth .lips:nth-child(n+5) {
  margin-left: 124px;
}
.mouth .lips:nth-child(n+6) {
  margin-left: 155px;
}
.mouth .lips:nth-child(n+7) {
  margin-left: 186px;
}
.mouth .lips:nth-child(n+8) {
  margin-left: 217px;
}
.mouth .lips:nth-child(n+9) {
  margin-left: 248px;
}
@-webkit-keyframes bobble {
  0%, 100% {
    margin-top: 40px;
    margin-bottom: 48px;
    box-shadow: 0 40px 80px rgba(0, 0, 0, 0.24);
 }
  50% {
    margin-top: 54px;
    margin-bottom: 34px;
    box-shadow: 0 24px 64px rgba(0, 0, 0, 0.40);
 }
}
@keyframes bobble {
  0%, 100% {
    margin-top: 40px;
    margin-bottom: 48px;
    box-shadow: 0 40px 80px rgba(0, 0, 0, 0.24);
 }
  50% {
    margin-top: 54px;
    margin-bottom: 34px;
    box-shadow: 0 24px 64px rgba(0, 0, 0, 0.40);
 }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .browser {
    width: 280px;
    margin-left: -140px;
 }
  .browser .eye {
    left: 30%;
 }
  .browser .eye + .eye {
    left: auto;
    right: 30%;
 }
}

